import { Link } from '@remix-run/react'

export default function Footer() {
    return (
        <footer className="w-3/4 bg-white">
            <div className="mb-4">
                <div className="flex-col-2 flex">
                    <div className="flex w-2/3 flex-col">
                        <div className="flex flex-row">About</div>
                        <hr className="w-5/6" />
                        <div className="mt-4 w-5/6 text-xs">
                            EarthOptics is a young, independent company with new ideas; we
                            don&apos;t sell seed, fertilizer or crop protection products. We see
                            soil differently. Our company is an organization of people with diverse
                            backgrounds who believe in agriculture, creativity and great science.
                        </div>
                    </div>
                    <div className="flex w-1/3 flex-col">
                        <div className="flex flex-row">Contact Us</div>
                        <hr className="w-5/6" />
                        <div className="mt-4 text-sm">
                            <Link to="mailto:info@earthoptics.com">info@earthoptics.com</Link>
                        </div>
                        <div className="mt-2 text-sm">
                            <Link to="tel:7032393218">(703)239-3218</Link>
                        </div>
                        <div className="mt-2 text-sm">
                            <Link to="https://earthoptics.com/contact-us">Contact Form</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
